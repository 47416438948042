let ru = {
    signIn: "Вход",
    signUp: "Регистрация",
    login: "Логин",
    password: "Пароль",
    name: "Имя",
    validation_notEmpty: "Поле обязательно.",
    validation_minLingth: "Не менее {0} символов.",
    button_cards: "Карточки",
    loading: "Загрузка",
    next: "Далее",
    shuffle: "Перемешать",
    appears_often: "Показывать чаще",
    appears_seldom: "Показывать реже",
    oops: "Ой...",
}

export default ru;