import React from "react";
import "../css/authentication.css";
import fetchWithBenefits from "../hooks/fetcher";
import { useTranslator } from '../localization/translation'

export default function Authentication(props) {
    const loginMode = 'login';
    const registrationMode = 'register';
    const translator = useTranslator();

    const [mode, setMode] = React.useState(loginMode);
    const [user, setUser] = React.useState({ login: "", password: "", name: "" });
    const [isBusy, setIsBusy] = React.useState(false);
    const [validationErrors, setValidationErrors] = React.useState(null);

    function validateUser() {
        let errors = {};
        if (!user.login) {
            errors.login = translator.validation_notEmpty;
        }
        if (!user.password) {
            errors.password = translator.validation_notEmpty;
        }
        if (mode === registrationMode) {
            if (user.login.length < 5) {
                errors.login = translator.formatString(translator.validation_minLingth, "5");
            }
            if (user.password.length < 5) {
                errors.password = translator.formatString(translator.validation_minLingth, "5");
            }
            if (!user.name) {
                errors.name = translator.validation_notEmpty;
            }
        }
        return Object.keys(errors).length > 0 ? errors : null;
    }

    function request(e) {
        e.preventDefault();
        if (isBusy) {
            return;
        }
        setIsBusy(true);
        let errors = validateUser();
        setValidationErrors(errors);

        if (errors) {
            setIsBusy(false);
            return;
        }

        let uri = `api/account/${mode === loginMode ? "login" : "register"}`

        fetchWithBenefits(uri, "POST", 5000, false, JSON.stringify(mode === loginMode ? { login: user.login, password: user.password } : user))
            .then(async response => {
                if (response.ok) {
                    props.setJwt(await response.json());
                } else {
                    setValidationErrors({ response: response.status === 400 ? (await response.json()).title : response.statusText });
                }
            })
            .finally(() => setIsBusy(false));
    }

    React.useEffect(() => {
        if (validationErrors) setValidationErrors(validateUser());
    }, [user]);

    let responseError = validationErrors?.response;
    return (
        <div className="auth-form">
            <div className="auth-mode">
                <button type="button" className={mode === loginMode ? "selected" : ""} onClick={() => setMode(loginMode)}>{translator.signIn}</button>
                <button type="button" className={mode === registrationMode ? "selected" : ""} onClick={() => setMode(registrationMode)}>{translator.signUp}</button>
            </div>
            <form onSubmit={request}>
                {mode === loginMode ?
                    <Login user={user} setUser={setUser} errors={validationErrors} /> :
                    <Registration user={user} setUser={setUser} errors={validationErrors} />}
                <label className={"error"}>{responseError}</label>
                <div className="auth-sub-btn-wrapper">
                    <button disabled={isBusy} onClick={e => request(e)} className="auth-sub-btn" type="submit">{mode === loginMode ? translator.signIn : translator.signUp}</button>
                </div>
            </form>
        </div>)
}

function Login(props) {
    const user = props.user;
    const setUser = props.setUser;
    const loginError = props.errors?.login;
    const passwordError = props.errors?.password;
    const translator = useTranslator();

    return (
        <div className="auth-fields">
            <label htmlFor="login">{translator.login}</label>
            <input id="login" className={loginError ? "error" : ""} name="login" autoComplete="false" value={user.login} onChange={(e) => { setUser({ ...user, login: e.target.value }) }}></input>
            <label htmlFor="login" className={"error"}>{loginError}</label>

            <label htmlFor="password">{translator.password}</label>
            <input id="password" className={passwordError ? "error" : ""} name="password" type="password" value={user.password} onChange={(e) => { setUser({ ...user, password: e.target.value }) }}></input>
            <label htmlFor="password" className={"error"}>{passwordError}</label>
        </div>
    );
}

function Registration(props) {
    const user = props.user;
    const setUser = props.setUser;
    const loginError = props.errors?.login;
    const passwordError = props.errors?.password;
    const nameError = props.errors?.name;
    const translator = useTranslator();

    return (
        <div className="auth-fields">
            <label htmlFor="login">{translator.login}</label>
            <input id="login" className={loginError ? "error" : ""} name="login" autoComplete="false" value={user.login} onChange={(e) => { setUser({ ...user, login: e.target.value }) }}></input>
            <label htmlFor="login" className={"error"}>{loginError}</label>

            <label htmlFor="name">{translator.name}</label>
            <input id="name" className={nameError ? "error" : ""} name="name" autoComplete="false" value={user.name} onChange={(e) => { setUser({ ...user, name: e.target.value }) }}></input>
            <label htmlFor="name" className={"error"}>{nameError}</label>

            <label htmlFor="password">{translator.password}</label>
            <input id="password" className={passwordError ? "error" : ""} name="password" type="password" value={user.password} onChange={(e) => { setUser({ ...user, password: e.target.value }) }}></input>
            <label htmlFor="password" className={"error"}>{passwordError}</label>
        </div>
    );
}