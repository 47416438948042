import React from "react";
import Layout from "./Layout";
import WP from '../utils/WordProcessor'
import { useNavigate } from 'react-router-dom';
import { UpArrow, DownArrow, Reshuffle } from '../svg/Icons'
import { useTranslator } from '../localization/translation'
import fetchWithBenefits from "../hooks/fetcher";
import '../css/card.css'

const dictionaryKey = 'dict';
const defaultTimeout = 5000;

function getLocalDictionary() {
    let dictionary = localStorage.getItem(dictionaryKey);
    if (dictionary) {
        try {
            return JSON.parse(dictionary);
        }
        catch {
            console.warn('local dictionary is corrupt');
            localStorage.removeItem(dictionaryKey);
        }
    }
    return null;
}

function saveLocalDictionary(dictionaryObject) {
    localStorage.setItem(dictionaryKey, JSON.stringify(dictionaryObject));
}

function shuffleWithPrioritets(array) {
    return array
        .map((x, i) => ({
            order: (Math.random() + 0.25 * (x.state ?? 0)),
            obj: x,
            originalIndex: i
        }))
        .sort((x, y) => y.order - x.order)
        .map(x => x.obj);
}

export default function WordCardsExercise() {
    const [error, setError] = React.useState(null);
    const [dictionary, setDictionary] = React.useState();
    const navigate = useNavigate();

    React.useEffect(() => {
        const fetchDictionaryAsync = () => {
            let uri = 'api/words';
            fetchWithBenefits(uri, "GET", defaultTimeout, true)
                .then(response => {
                    if (response.ok) {
                        return response.json();
                    }
                    else {
                        return Promise.reject({ message: 'Failed when fetching data.', code: response.status });
                    }
                })
                .then(obj => {
                    saveLocalDictionary(obj);
                    setDictionary(obj);
                })
                .catch(e => {
                    console.error(e);
                    e.code === 401 ? navigate('/') : setError(e);
                });
        }

        let localDictionary = getLocalDictionary();
        if (localDictionary != null) {
            let uri = 'api/words/version';
            fetchWithBenefits(uri, "GET", defaultTimeout, true)
                .then(response => {
                    if (response.ok) {
                        return response.json();
                    }
                    else {
                        return Promise.reject({ message: 'Failed when getting remote versions.', code: response.status });
                    }
                })
                .then(async (ver) => {
                    if (ver.uVer === localDictionary.uVer && ver.dVer === localDictionary.dVer) {
                        console.info('use local dictionary');
                        setDictionary(localDictionary);
                    }
                    else {
                        console.info('fetching dictionary from server (local outdated)');
                        await fetchDictionaryAsync();
                    }
                })
                .catch(err => {
                    setError(err.name === 'AbortError' ? { message: 'timeout' } : err);
                });
        }
        else {
            console.info('fetching dictionary from server (local empty)');
            fetchDictionaryAsync();
        }

    }, [navigate]);

    if (dictionary) {
        return <Cards dictionary={dictionary} />
    }
    else {
        return error ? <Error
            message={error.message}
            localDictionaryExist={localStorage.hasOwnProperty(dictionaryKey)}
            useLocalDictionary={() => setDictionary(getLocalDictionary())}
        />
            : <Loading />;
    }
}

function Cards(props) {

    const translator = useTranslator();
    const navigate = useNavigate();

    const [dictionary] = React.useState(props.dictionary);
    const [shuffledDictionary, setShuffledDictionary] = React.useState(shuffleWithPrioritets(dictionary.words));
    const [currentItem, setCurrentitem] = React.useState(shuffledDictionary[0]);
    const [currentIndex, setCurrentIndex] = React.useState(0);

    const next = React.useCallback(() => {
        setCurrentIndex(i => shuffledDictionary[i + 1] ? i + 1 : 0);
    }, [shuffledDictionary]);

    const previous = React.useCallback(() => {
        setCurrentIndex(i => shuffledDictionary[i - 1] ? i - 1 : shuffledDictionary.length - 1);
    }, [shuffledDictionary]);

    const reveal = React.useCallback(() => {
        setCurrentitem({ ...currentItem, isHidden: false });
    }, [currentItem]);

    const setState = React.useCallback((id, newState) => {
        let item = dictionary.words.find(x => x.id === id);

        const uri = 'api/words/setstate';
        fetchWithBenefits(uri, "POST", defaultTimeout, true, JSON.stringify({ wordId: id, state: newState }))
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                else {
                    if (response.code === 401) {
                        navigate('/');
                    }
                    else {
                        return Promise.reject({ message: 'Failed when updating state.', code: response.status });
                    }
                }
            })
            .then(j => {
                console.info(j);
                if (j.uVer) {
                    item.state = newState;
                    dictionary.uVer = j.version;
                    saveLocalDictionary(dictionary);
                    setCurrentitem(x => ({ ...x, state: newState }));
                }
            })
            .catch(e => {
                console.error(e);
            });
    }, [dictionary, navigate, setCurrentitem]);

    const reportCurrentWord = React.useCallback(() => {
        console.log("report " + currentItem.id);
        let msg = prompt('What\'s wrong?');
        fetchWithBenefits('api/words/report', 'POST', defaultTimeout, true,JSON.stringify({ wordId: currentItem.id, Description: msg }))
            .then(response => {
                if (response.ok) {
                    console.log('reported');
                }
                else {
                    return Promise.reject({ message: response.statusText, code: response.status });
                }
            })
            .catch(x => console.error(x));
    }, [currentItem]);

    const onHightPrioritet = React.useCallback(_ => { setState(currentItem.id, currentItem.state > 0 ? 0 : 1) }, [currentItem, setState]);

    const onLowPrioritet = React.useCallback(_ => { setState(currentItem.id, currentItem.state < 0 ? 0 : -1); }, [currentItem, setState]);

    React.useEffect(() => {
        setCurrentitem({ ...shuffledDictionary[currentIndex], isHidden: true });
    }, [currentIndex, shuffledDictionary]);

    React.useEffect(() => {
        const onKeyDown = (e) => {
            if (e.target?.tagName?.toUpperCase() === 'INPUT') {
                return;
            }
            if (e.code === "Numpad7") {
                onLowPrioritet();
            }
            else if (e.code === "Numpad9") {
                onHightPrioritet();
            }
            else if (e.code === "Numpad5") {
                currentItem.isHidden ? reveal() : next();
            }
            else if (e.code === "Numpad4") {
                previous();
            }
            else if (e.code === "Numpad6") {
                next();
            }
        };
        document.addEventListener('keydown', onKeyDown);
        return () => document.removeEventListener('keydown', onKeyDown)
    }, [onHightPrioritet, onLowPrioritet, currentItem, next, previous, reveal]);

    return (
        <Layout>
            <div id="idk42">
                <span>{currentIndex + 1} of {shuffledDictionary.length}</span>
                <button type="button" onClick={reportCurrentWord}>!</button>
                <button
                    onClick={() => setShuffledDictionary(shuffleWithPrioritets(dictionary.words))}
                    title={translator.shuffle}
                    className={'svg-button'}
                    type="button">
                    <Reshuffle />
                </button>
            </div>
            <Card word={currentItem} reveal={reveal}></Card>
            <div className='buttons-row'>
                <button
                    onClick={onLowPrioritet}
                    title={translator.appears_seldom}
                    className={`svg-button btn-prior btn-down ${shuffledDictionary[currentIndex].state < 0 ? "pressed" : ""}`}
                    type="button"> <DownArrow /></button>
                <button
                    className='btn-next' onClick={next}
                    type="button">{translator.next}</button>
                <button
                    onClick={onHightPrioritet}
                    title={translator.appears_often}
                    className={`svg-button btn-prior btn-up ${shuffledDictionary[currentIndex].state > 0 ? "pressed" : ""}`}
                    type="button"> <UpArrow /></button>
            </div>
        </Layout >
    );
}

function Card(props) {
    const [showRus, setShowRus] = React.useState(true);
    const eeForms = WP(props.word.ee);
    const ruWord = props.word.ru;
    const eeWord = `${eeForms[0]}\n(${eeForms.join(', ')})`;

    return (
        <div className='card'>
            <div className='word word-top'>{showRus ? ruWord : eeWord}</div>
            <div onClick={props.reveal} className={`word ${props.word.isHidden ? "word-hidden" : ""}`}>{`${showRus ? eeWord : ruWord}`}</div>
            <hr className="mid-split" />
            <button type="button" className="flipper" onClick={() => setShowRus(!showRus)}>￪￬</button>
        </div>
    );
}

function Loading() {
    const translator = useTranslator();
    return (
        <Layout>
            <div className="placeholder-loading">{translator.loading}...</div>
        </Layout>
    );
}

function Error(props) {
    const translator = useTranslator();
    return <Layout>
        <div>
            {translator.oops}
            <br></br>
            {props.message}
        </div>
        {props.localDictionaryExist && <button onClick={props.useLocalDictionary}>{'translator.goOffline'}</button>}
    </Layout>
}