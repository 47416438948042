let en = {
    signIn: "Sign in",
    signUp: "Sign up",
    login: "Login",
    password: "Password",
    name: "Name",
    validation_notEmpty: "Field is required.",
    validation_minLingth: "Must be at least {0} characters.",
    button_cards: "Cards",
    loading: "Loading",
    next: "Next",
    shuffle: "Shuffle",
    appears_often: "Show more often",
    appears_seldom: "Show less often",
    oops: "Oops...",
}

export default en;