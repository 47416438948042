import LocalizedStrings from "react-localization";
import strings from "./resources/lang";

const storedLanguageKey = "appLang";
const localizedStrings = new LocalizedStrings(strings);

export const useTranslator = () => {
    localizedStrings.setLanguage(getAppLanguage());
    return localizedStrings;
}
export function useLanguageSetter() {
    function changeLanguage(lang) {
        localStorage.setItem(storedLanguageKey, lang);
    }

    return { language: getAppLanguage(), changeLanguage };
}

function getAppLanguage() {
    return localStorage.getItem(storedLanguageKey) ?? selectDefaultLanguage();

    function selectDefaultLanguage() {
        let interfaceLang = localizedStrings.getInterfaceLanguage();
        let availableLnags = localizedStrings.getAvailableLanguages();
        return availableLnags.includes(interfaceLang) ? interfaceLang : availableLnags[0];
    }
};