export default function Process(str) {
    if (!str) {
        throw new Error('Can not process null or empty string.');
    }

    try {
        const parts = str.split(',').map(x => x.trim());
        if (parts.length !== 3) {
            return [str];
        }
        var hasRoot = parts[0].includes("/");
        var w1 = parts[0].split('/');

        let result = [];
        result.push(hasRoot ? w1[0] + w1[1] : parts[0]);
        result.push(parts[1][0] === '-' ? w1[0] + parts[1].substring(1) : parts[1]);
        result.push(parts[2][0] === '-' ? w1[0] + parts[2].substring(1) : parts[2]);

        return result;
    } catch { return [str]; }
}