export default async function fetchWithBenefits(uri, method, timeout, auth, body) {
    let timeoutId;

    let headers = {
        "Accept": "application/json"
    };
    if (auth) {
        headers.Authorization = "Bearer " + localStorage.getItem('jwt')
    }

    let params = {
        method: method,
        headers: headers
    }

    if (timeout > 0) {
        const controller = new AbortController();
        timeoutId = setTimeout(() => controller.abort(), timeout);
        params.signal = controller.signal;
    }

    if (body) {
        params.body = body;
        params.headers["Content-Type"] = "application/json"
    }
    let response = await fetch(uri, params);
    clearTimeout(timeoutId);
    return response;
}
