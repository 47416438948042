import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './components/App';
import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";
import NotFound from './components/NotFound';
import WordCardsExercise from './components/WordCards';

const root = ReactDOM.createRoot(document.getElementById('root'));

const router = createBrowserRouter([
    {
        path: "/",
        element: <App />
    },
    {
        path: "/wordcards",
        element: <WordCardsExercise />
    },
    {
        path: "*",
        element: <NotFound />
    }
]);
root.render(
    <React.StrictMode>
        <RouterProvider router={router} />
    </React.StrictMode>
);
