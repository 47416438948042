import 'normalize.css'
import '../css/general.css';

import React from "react"
import Authentication from "./Authentication";
import Layout from './Layout';
import ExerciseMenu from './ExerciseMenu';

const storedJwtKey = 'jwt';

export default function App() {
    const [jwt, setJwt] = React.useState(localStorage.getItem(storedJwtKey));

    function saveJwt(value) {
        localStorage.setItem(storedJwtKey, value);
        setJwt(value);
    }

    let userName = null;
    if (jwt) {
        userName = JSON.parse(atob(jwt.split('.')[1]))["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name"];
    }

    return (
        <Layout title={jwt ? userName : "Tere!"}>
            {jwt ? <ExerciseMenu/> : <Authentication setJwt={saveJwt} />}
        </Layout>
    );
}