import { useNavigate } from 'react-router-dom';
import { useTranslator } from '../localization/translation';
import  '../css/exercise-menu.css';

export default function ExerciseMenu() {
    const navigate = useNavigate();
    const translator = useTranslator();
    return (
        <div className="exercise-menu">
            <button type="button" onClick={() => navigate('wordcards')}>{translator.button_cards}</button>
        </div>);
}