import { Home, Gear } from '../svg/Icons.jsx'
import { Link } from 'react-router-dom'
import headCss from '../css/header.module.css'
import { useLanguageSetter } from '../localization/translation'
import { useNavigate } from 'react-router-dom';

export default function Header(props) {
    const { language, changeLanguage } = useLanguageSetter();
    const navigator = useNavigate();
    return (
        <div className={headCss.header}>
            <div className={headCss.left}>
                <Link to="/"><Home /></Link>
            </div>
            <div>
                <div className={headCss.flexGrow}>{props.title}</div>
            </div>
            <div className={headCss.right}>
                <select value={language} onChange={(e) => { changeLanguage(e.target.value); navigator(0); }}>
                    <option value="en">En</option>
                    <option value="ru">Ru</option>
                </select>
            </div>
        </div>
    )
}